$(function () {
    var today = new Date();

    today.setHours(0,0,0,0);

    $('.datetime').datetimepicker({
        format: 'DD/MM/YYYY',
        ignoreReadonly: true,
        minDate: today,
        useCurrent: false,
        keepInvalid: true
    });
});

function getAvailability() {
    var postcode = $('#postcode').val();
    var pickupDate = $('#pickup_date');

    if (postcode.length < 5) {
        return;
    }

    pickupDate.val('');

    $.get('/returns/availability', { 'postcode' : postcode })
        .done(function (data) {
            pickupDate.data("DateTimePicker").enabledDates(data);
            pickupDate.data("DateTimePicker").defaultDate(data[0]);
            pickupDate.show();
        });
}

$(document).ready(function () {
    var postcode = $('#postcode');
    var pickupDate = $('#pickup_date');

    if (typeof pickUpDate !== 'undefined' && pickupDate.val().length === 0) {
        pickupDate.hide();
    }

    postcode.on('change', function() {
        getAvailability();
    });

    pickupDate.on('focus', function () {
        getAvailability();
    });
});
