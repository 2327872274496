$(document).ready(function () {
    var $dynamicTable = $('.dynamic-table-wrapper');

    if (! $dynamicTable.length) {
        return;
    }

    $dynamicTable.on('click', '.add-column', function () {
        var $lastRow = $(this).prev('table').find('.table-column:last'),
            $formRow = $lastRow.clone();

        $formRow.find('input').each(function () {
            this.name = this.name.replace(/\[(\d+)\]/, function (str, p1) {
                return '[' + (parseInt(p1, 10) + 1) + ']'
            });

            $(this).val('');
        }).end();

        $lastRow.after($formRow);
    });

    $dynamicTable.on('click', '.remove-column', function () {
        var $thisRow = $(this).parents('.table-column');

        $thisRow.prev('.table-column-errors').remove();

        if ($thisRow.index() > 0) {
            $thisRow.remove();
        } else {
            $thisRow.find('input').each(function() {
                $(this).val('');
            });
        }
    });

    var pickUpDate = $('#pickup_date');
    var postcode = $('#postcode');

    if (typeof pickUpDate !== 'undefined' && pickUpDate.val().length === 0) {
        pickUpDate.hide();
    }

    postcode.change(function() {
        getAvailability();
    });

    pickUpDate.click(function() {
        getAvailability();
    });
});

function getAvailability() {
    var postcode = $('#postcode');

    if (postcode.val().length < 5) {
        return;
    }

    $('#pickup_date').val('');

    $.get('/returns/availability', { 'postcode' : postcode.val() })
        .done(function(data) {
            $('#pickup_date').data("DateTimePicker").enabledDates(data);
            $('#pickup_date').data("DateTimePicker").defaultDate(data[0]);
            $('#pickup_date').show();
        });
}
